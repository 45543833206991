<template>
  <div :v-loading="loading">
    <router-view />
  </div>
</template>

<script>
import { computed } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

export default {
  name: "Home",
  setup() {
    let loading = true;

    const store = useStore();
    const router = useRouter();

    const user = computed(() => store.state.user);

    if (store.state.auth.token === null || !store.state.auth.isAuthenticated) {
      router.push({ name: "login" });
      return;
    }

    if (user.value.currentUser.OrganizationUserId === 0) {
      router.push({ name: "admin" });
      return;
    }

    if (user.value.currentUser.Organization !== null) {
      router.push({ name: "organization-team" });
      return;
    }

    this.$store.dispatch("wizard/removeToken");
    this.$store.dispatch("wizard/removeUser");
    this.$store.dispatch("wizard/removeOrganization");

    loading = false;

    return {
      loading,
      user,
    };
  },
};
</script>
